import React, { useCallback } from 'react';
import Particles from "react-particles";
import { loadFull } from "tsparticles";
import type { Engine } from "tsparticles-engine";
import './App.css'
import particlesOptions from "./particles2.json";

function App() {
    const particlesInit = useCallback(async (engine: Engine) => {
        await loadFull(engine);
    }, []);

    return (
      <>
        <div className="App">
            <Particles options={particlesOptions} init={particlesInit}/>
       </div>
        </>
    );
}

export default App;
